import clsx from "clsx";
import { useEffect } from "react";
import { PostMedia } from "..";
import { TopicPopup, useTopicsModalQuery } from "../../features";
import s from "./topic.module.css";

type Props = {
  data: any;
  number: number;
  className?: string;
  referenceId?: string;
  tonal?: string;
  profileId?: string;
  networkId?: string;
  type?: string
};

export const Topic = ({
  number,
  className,
  data,
  referenceId,
  tonal,
  profileId,
  networkId,
  type
}: Props) => {
  const postMediaData = {
    id: data?.id,
    group_id: data?.group_id,
    topic: data?.title,
    subject: data?.reference_item,
    level: 0,
    subjectPublications: data?.postcount,
    subjectCoverage: data?.attendance,
    totalPublications: data?.total_posts,
    totalCoverage: data?.total_attendance,
    dynamicData: data?.total_graph,
    mediaPublications: data?.smi_total_posts,
    socialMediaPublications: data?.social_total_posts,
    storyDocs: data?.storyDocs,
    lastHourDocs: data?.lastHourDocs,
    publications: data?.details,
  };

  const postNetural = data?.details.items.filter(
    (it: any) => it.trust.trust === 0
  ).length;
  const postNegative = data?.details.items.filter(
    (it: any) => it.trust.trust === -1
  ).length;
  const postPositive = data?.details.items.filter(
    (it: any) => it.trust.trust === 1
  ).length;
  const postSumm = postNetural + postNegative + postPositive;
  tonal =
    (postNegative * 100) / postSumm >= 25
      ? "trending_negative"
      : (postPositive * 100) / postSumm >= 25
      ? "trending_positive"
      : "";

  // const postFederal = data?.details.items.filter(
  //   (it: any) => it.smi_type === 'federal'
  // ).length;


  // useEffect(() => {
  //   const listOfSources = data?.details.items.map(
  //     (it: { owner_id: any; author: any; }) => ({
  //       id: [
  //         it.owner_id,
  //       ].toString(),
  //       keyword: it.author,
  //     })
  //   );
  //   updateData(listOfSources)
  // }, [data?.details.items]);

  // const postBySource = data?.details.items.filter(
  //   (it: any) => it.owner_id === profileId
  // ).length;

  const content = (
    <div className={className}>
      {/*{profileId != "all" && profileId != "" && postBySource > 0 && (*/}
        <PostMedia
          number={number}
          coat={data?.smi_type === "federal" || data?.isFederal}
          className={clsx(s.topic, s[tonal])}
          data={postMediaData}
        />
      {/*)}*/}
      {/*{(profileId == "" || profileId == "all") &&(*/}
      {/*  <PostMedia*/}
      {/*    number={number}*/}
      {/*    coat={data?.smi_type === "federal" || data?.isFederal}*/}
      {/*    className={clsx(s.topic, s[tonal])}*/}
      {/*    data={postMediaData}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );

  return (
    <TopicPopup
      content={content}
      data={postMediaData}
      referenceId={referenceId}
      profileId={profileId}
      type={type}
    />
  );
};
