import { useNewsbreakListQuery } from "../../features";
import { Field, PageTitle, Panel, ApiInfo, TextLoader } from "../../ui";
import { NewsBreakItem } from "./newsbreakItem";
import s from "./newsbreak.module.css";
import clsx from "clsx";
import {useState, ChangeEvent, useEffect} from "react";
import {ApiServices} from "../../services/api-services";
import CustomSelect from "../../ui/custom-select/custom-select";
import {convertToSelectorList} from "../../utils/convert-to-selector-list";

export function Newsbreak() {
  const [searchString, setSearchString] = useState('');
  const [searchResponsible, setSearchResponsible] = useState('');
  const [searchIOGV, setSearchIOGV] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [items, setItems] = useState<Array<any>>([]);
  const [filteredData, setFilteredData] = useState<Array<any>>([]);
  const [filterInProgress, setFilterInProgress] = useState(true);
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [responsibleList, setResponsibleList] = useState<Array<{id: string, keyword: string}>>([]);
  const [responsibleListIsLoading, setResponsibleListIsLoading] = useState(true);
  const [iogvList, setIogvList] = useState<Array<{id: string, keyword: string}>>([]);
  const [iogvListIsLoading, setIogvListIsLoading] = useState(true);

  const queryData = useNewsbreakListQuery({ start: 0, limit: 1000, filter:{author:'', iogv_id:0, status:searchStatus} });
  const { data, isSuccess, isLoading, isError, error } = queryData;

  const dataLoading = (isSuccess && filteredData?.length > 0) || !isLoading ? false : true;

  useEffect(() => {
    getAllData()
  }, [data])

  const getAllData = async () => {
    if (data?.items) {
      const tempItems: any[] = []
      for (const item of data.items) {
        // console.log('item', item)
        const itemResponse = await ApiServices.getInpdItem({ id: item.id })
        // console.log('itemResponse', itemResponse.data)
        if (itemResponse.data) {
          tempItems.push({
            ...item,
            details: itemResponse.data
          })
        }
      }
      setItems(tempItems)
      setDataIsLoading(false)
    }
  }

  useEffect(() => {
    if (items.length > 0) {
      setResponsibleList(convertToSelectorList(items, 'responsible_id'))
      setIogvList(convertToSelectorList(items, 'iogv_id'))
      setResponsibleListIsLoading(false)
      setIogvListIsLoading(false)
    }
  }, [items])

  useEffect(() => {
    setFilterInProgress(true)
    if (items) {
      let filteredItems = [...items]
      if (searchString !== '') {
        filteredItems = [...filteredItems].filter((it: any) => it.title.toLowerCase().includes(searchString.toLowerCase()))
      }
      if (searchResponsible !== '') {
        filteredItems = [...filteredItems].filter((it: any) => it.details.responsible_id.title?.toLowerCase().includes(searchResponsible.toLowerCase()))
      }
      if (searchIOGV !== '') {
        filteredItems = [...filteredItems].filter((it: any) => it.details.iogv_id.title?.toLowerCase().includes(searchIOGV.toLowerCase()))
      }
      setFilteredData(filteredItems)
    }
    setFilterInProgress(false)
  }, [searchString, searchResponsible, searchIOGV, items])

  const searchHandle = (value: string) => {
    setSearchString(value)
  }

  return (
    <>
      <PageTitle>Инфоповоды</PageTitle>
      <Panel className={s.search} padding>
        <Field
          placeholder="Введите название"
          icon="search"
          className={s.searchField}
          iconClickHandle={searchHandle}
        />
        <div className={s.fieldList}>
          <div className={s.field}>
            <span>Ответственный</span>
            <CustomSelect
              items={responsibleList}
              isLoading={responsibleListIsLoading}
              onChange={(value) => setSearchResponsible(String(value))}
              value={searchResponsible}
              withSearch={true}
              isWide={true}
            />
          </div>
          <div className={s.field}>
            <span>ИОГВ</span>
            <CustomSelect
              items={iogvList}
              isLoading={iogvListIsLoading}
              onChange={(value) => setSearchIOGV(String(value))}
              value={searchIOGV}
              withSearch={true}
              isWide={true}
            />
          </div>
        </div>
        <span className={s.count}>
          Всего инфоповодов:{" "}
          {isLoading ? <TextLoader /> : filteredData.length ?? "–"}
        </span>
      </Panel>

      <ApiInfo isLoading={dataLoading || filterInProgress || dataIsLoading} isError={isError} error={error} />

      {isSuccess && !dataIsLoading &&(
        <div className={s.list}>
          {filteredData.map((item: any, index: number) => (
            <NewsBreakItem key={item.id} number={index + 1} {...item} />
          ))}
        </div>
      )}
    </>
  );
}
