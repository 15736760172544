import clsx from "clsx";
import { Link } from "react-router-dom";
import {ChangeEvent, useEffect, useState} from "react";
import {useThreadItemQuery, useThreadsQuery, useGetAllPostsQuery} from "../../features";
import { ApiInfo, PageTitle, Panel, Field, Button, TextLoader } from "../../ui";
import { Material } from "./material";
import s from "./materials.module.css";
import {ApiServices} from "../../services/api-services";
import CustomSelect from "../../ui/custom-select/custom-select";
import {convertToSelectorList} from "../../utils/convert-to-selector-list";

export function Materials() {
  const [searchString, setSearchString] = useState('')
  const [searchResponsible, setSearchResponsible] = useState('')
  const [searchIOGV, setSearchIOGV] = useState('');
  const [items, setItems] = useState<Array<any>>([]);
  const [filteredData, setFilteredData] = useState<Array<any>>([]);
  const [responsibleList, setResponsibleList] = useState<Array<{id: string, keyword: string}>>([]);
  const [responsibleListIsLoading, setResponsibleListIsLoading] = useState(true);
  const [iogvList, setIogvList] = useState<Array<{id: string, keyword: string}>>([]);
  const [iogvListIsLoading, setIogvListIsLoading] = useState(true);
  const [dataIsLoading, setDataIsLoading] = useState(true);

  const queryData = useThreadsQuery("");
  const { data, isSuccess, isLoading, isError, error } = queryData;
  const allPosts = useGetAllPostsQuery({})

  useEffect(() => {
    getAllData()
  }, [data])

  const getAllData = async () => {
    setDataIsLoading(true)
    if (data) {
      let tempItems: any[] = []
      for (const item of data) {
        const itemResponse = await ApiServices.getThreadItem({ thread_id: +item.id })
        if (itemResponse.data && itemResponse.data.thread_id !== 0) {
          tempItems.push({
            ...item,
            details: itemResponse.data
          })
        }
      }
      setItems(tempItems)
    }
    setDataIsLoading(false)
  }

  useEffect(() => {
    if (items.length > 0) {
      setResponsibleList(convertToSelectorList(items, 'responsible'))
      setIogvList(convertToSelectorList(items, 'author_iogv'))
      setResponsibleListIsLoading(false)
      setIogvListIsLoading(false)
    }
  }, [items])

  useEffect(() => {
    if (items) {
      let tempItems: any[] = items.filter((it: any) => it.details.thread_id !== 0)
      if (searchString) {
        tempItems = [...tempItems].filter((it: any) => it.threadname.toLowerCase().includes(searchString.toLowerCase()))
      }
      if (searchResponsible) {
        tempItems = [...tempItems].filter((it: any) => it.details.responsible.toLowerCase().includes(searchResponsible.toLowerCase()))
      }
      if (searchIOGV) {
        tempItems = [...tempItems].filter((it: any) => it.details.author_iogv.toLowerCase().includes(searchIOGV.toLowerCase()))
      }
      setFilteredData(tempItems)
    }
  }, [searchResponsible, searchIOGV, searchString, items])

  const searchSubmit = (value: string) => {
    setSearchString(value);
  };

  return (
    <>
      <PageTitle>Готовые материалы</PageTitle>
      <Panel className={s.search} padding>
        <Field
          className={s.field}
          icon="search"
          iconClickHandle={searchSubmit}
          placeholder="Введите название"
        />
        <div className={s.fieldList}>
          <div className={s.field}>
            <span>Ответственный</span>
            <CustomSelect
              items={responsibleList}
              isLoading={responsibleListIsLoading}
              onChange={(value) => setSearchResponsible(String(value))}
              value={searchResponsible}
              withSearch={true}
              isWide={true}
            />
          </div>
          <div className={s.field}>
            <span>ИОГВ</span>
            <CustomSelect
              items={iogvList}
              isLoading={iogvListIsLoading}
              onChange={(value) => setSearchIOGV(String(value))}
              value={searchIOGV}
              withSearch={true}
              isWide={true}
            />
          </div>
        </div>
        <span className={s.count}>
          Всего готовых материалов: {" "}
           {isLoading ? <TextLoader /> : allPosts.data?.length-2 ?? "–"}
        </span>
      </Panel>

      <ApiInfo isLoading={isLoading || dataIsLoading} isError={isError} error={error} />

      {isSuccess && (
        <div className={s.list}>
          {filteredData.map((item: any, index: number) => (
            <Material number={index + 1} {...item} key={item.id + index} />
          ))}
        </div>
      )}
    </>
  );
}
