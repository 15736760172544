import React, { useEffect, useState, ChangeEvent } from 'react'
import './custom-select-styles.scss'

type Props = {
  items: Array<{ id: string | number, keyword: string }>
  onChange: (value: string | number) => void
  value: string | number
  type?: string
  isLoading?: boolean
  isWide?: boolean
  placeholder?: string
  withSearch?: boolean
}

const CustomSelect: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchField, setSearchField] = useState<string>('')
  const [items, setItems] = useState<{ id: string | number, keyword: string }[]>(props.items)

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', documentClickListener)
    } else {
      setSearchField('')
      document.removeEventListener('click', documentClickListener)
    }
    return () => document.removeEventListener('click', documentClickListener)
  }, [isOpen])

  useEffect(() => {
    if (searchField) {
      setItems([...props.items].filter((it: any) => it.keyword.toLowerCase().includes(searchField.toLowerCase())))
    } else {
      setItems(props.items)
    }
  }, [searchField, props.items])

  const documentClickListener = (): void => {
    setIsOpen(false)
  }

  const openHandle = (): void => {
    if (props.items?.length > 0) {
      setIsOpen(prevState => !prevState)
    }
  }

  const changeHandle = (id: string | number): void => {
    props.onChange(id)
    setIsOpen(false)
  }

  const searchHandle = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchField(evt.target.value)
  }

  const clearSearch = () => {
    setSearchField('')
  }

  return (
    <div
      className={`custom-select${props.type === 'interval' ? ' interval' : ''}${isOpen ? ' active' : ''}${props.items?.length > 0 ? '' : ' disabled'}${props.isLoading ? ' loading' : ''}${props.isWide ? ' wide' : ''}`}
    >
      <div className="custom-select__wrap">
        <div className="custom-select__head">
          {props.withSearch && isOpen
            ? (
              <div className="custom-select__search" onClick={event => event.stopPropagation()}>
                <input type="text" value={searchField} onChange={searchHandle} placeholder="Найти"/>
                {searchField?.length > 0 ? <span onClick={clearSearch} >×</span> : null}
              </div>
            )
            : (
              <div className="custom-select__current" onClick={openHandle}>
                <span>
                  {props.isLoading
                    ? 'Загрузка'
                    : props.items?.find((it) => it.id === props.value)?.keyword || props.placeholder || ' '
                  }
                </span>
                <svg viewBox="64 64 896 896" width="12px" height="12px" >
                  <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                </svg>
              </div>
            )
          }
        </div>

        <div className="custom-select-drop" onClick={event => event.stopPropagation()}>
          <div className="custom-select-list">
            {items?.map((item) => {
              return (
                <div
                  className={`custom-select-list__item${item.id === props.value ? ' current' : ''}`}
                  key={item.id}
                  onClick={() => changeHandle(item.id)}
                >
                  {item.keyword}
                </div>
              )
            })}
            {items?.length < 1 && searchField
              ? <div className="custom-select-list__empty">не найдено</div>
              : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomSelect
