import {
  FilterData,
  mergeQueries,
  toLocaleDateTime,
  useInfiniteQuery,
  usePublicationsQuery, useRefSourcesQuery,
  useStatsQuery,
  useThreadItemQuery
} from "../../features";

import {
  InfoPanel,
  InfoDescription,
  ApiInfo
} from "../../ui";

import s from "./press-secretary-info-item.module.css";
import {TrustValue} from "../../data";
import {useEffect, useState} from "react";
import {Scorecard} from "../../ui/scorecard";
import {TopSources} from "../../ui/top-sources";

type ApiParams = {
  thread_id?: string;
  from?: string;
  to?: string;
  start?: number;
  limit?: number;
  filter?: {
    network_id?: number | number[];
    trustoption?: TrustValue;
    referenceFilter?: number[];
  };
  sort?: {
    order?: string;
    type?: string
  }
};

type Props = {
  id: number
}
export const PressSecretaryInfoItem = ({ id }: Props) => {
  const [apiParams, setApiParams] = useState<ApiParams>({ thread_id: String(id) });
  const [expanded, expandFilters] = useState(false);

  const queryData = useThreadItemQuery({ thread_id: id });
  const { data, isSuccess, isLoading } = queryData;

  const { items: posts, query } = useInfiniteQuery(
    usePublicationsQuery,
    apiParams,
    (res: any) => res.data?.posts,
    !apiParams.thread_id
  );
  const allQueries = mergeQueries(query);
  const { isFetching, isError, error } = allQueries;

  const [typeId, setTypeId] = useState<string>(process.env.REACT_APP_SUBJECTS_ID as string);
  const [sourcesList, setSourcesList] = useState([] as any[]);
  const statsQuery = useStatsQuery("");
  const findType = (item: any) => item.id === +typeId;
  const activeTypeItems = statsQuery.data?.find(findType)?.items ?? [];

  const activeTypeIds = activeTypeItems?.map((item:any)=> item.id)
  const sourcesQuery = useRefSourcesQuery({
    thread_id: String(id),
    referenceFilter: activeTypeIds,
    network_id: [1, 2, 3, 4, 5, 7, 8],
    start: 0,
    limit: 10000
  });

  useEffect(() => {
    if (sourcesQuery.data) {
      setSourcesList(sourcesQuery.data.items || [])
    }
  }, [sourcesQuery])

  function onFiltersUpdate(data: FilterData) {
    setApiParams((params) => ({
      ...params,
      to: data.to,
      from: data.from,
      filter: {
        ...params.filter,
        network_id: data.network_id,
        trustoption: data.trustoption,
      },
      sort: {
        order: data.order,
        type: data.type
      }
    }));
  }

  if (!isSuccess) {
    return (
      <InfoPanel title="">
        <ApiInfo isLoading={isLoading} isError={isError} error={error} />
      </InfoPanel>
    );
  }

  return (
    <div className={s.pressSecretaryInfoItem}>
      <InfoPanel
        className={s.infopanel}
        title={queryData.data?.title}
      >
        <div className={s.columns}>
          <div>
            <InfoDescription
              className={s.description}
              title="Описание:"
              description={data.description || "-"}
            />
          </div>

        </div>
        <div className={s.columns}>
          <InfoDescription

            title="Подпрограмма:"
            description={data.subroutine?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Региональный проект:"
            description={data.regin_project?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Направления стратегии:"
            description={data.stategy?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Сфера:"
            description={data.sphere?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Каналы:"
            description={data.channels?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            //className={s.description}
            title="Локация:"
            description={data.location?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            title="Целевая аудитория:"
            description={data.ca?.map((it: string) => {
              return <span>{it}</span>
            })}
          />
          <InfoDescription
            title="Дата/время начала:"
            description={data.date_finish === "0000-00-00 00:00:00" ? "-" : toLocaleDateTime(data.date_start)}
          />
          <InfoDescription
            title="Дата/время окончания:"
            description={data.date_finish === "0000-00-00 00:00:00" ? "-" : toLocaleDateTime(data.date_finish)}
          />
          <InfoDescription title="Ответственный:" description={data.responsible} />
          <InfoDescription title="Соисполнители:" description={data.sub_responsible?.length > 0 ? data.sub_responsible?.map((it: string, idx: number) => {
            if (idx < data.sub_responsible.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Участники:" description={data.members?.length > 0 ? data.members?.map((it: string, idx: number) => {
            if (idx < data.members.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Дополнительные участники:" description={data.ext_members?.length > 0 ? data.ext_members?.map((it: string, idx: number) => {
            if (idx < data.ext_members.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Автор:" description={data.author} />
          <InfoDescription title="Хэштеги:" description={data.hashtag?.length > 0 ? data.hashtag?.map((it: string, idx: number) => {
            if (idx < data.hashtag.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription title="Риски:" description={data.risks?.length > 0 ? data.risks?.map((it: string, idx: number) => {
            if (idx < data.risks.length - 1) {
              return it + ', '
            }
            return it
          }) : "–"}/>
          <InfoDescription
            className={s.description_ref}
            title="Ссылка:"
            description={data.url.length > 0 ? <a target="_blank" href={data.url}>{data.url}</a> : "-"}
          />
        </div>
      </InfoPanel>

      <Scorecard id={id} sourcesId={data.sources_id} />
      <TopSources id={id} sourceType={'smi'} />
      <TopSources id={id} sourceType={'social'} />
    </div>
  );
}
