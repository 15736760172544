import clsx from "clsx";
import {ChangeEvent, useEffect, useState} from "react";
import { useRiskListQuery, useScrollPercent } from "../../features";
import { ApiInfo, Field, PageTitle, Panel, TextLoader } from "../../ui";
import { Risk } from "./risk";
import s from "./risks.module.css";
import CustomSelect from "../../ui/custom-select/custom-select";
import {ApiServices} from "../../services/api-services";
import moment from "moment";
import {convertToSelectorList} from "../../utils/convert-to-selector-list";

export function Risks() {
  const [searchString, setSearchString] = useState('');
  const [searchResponsible, setSearchResponsible] = useState('');
  const [searchIOGV, setSearchIOGV] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [sortSelected, setSortSelected] = useState<string>('status');
  const [items, setItems] = useState<Array<any>>([]);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [filteredData, setFilteredData] = useState<Array<any>>([]);
  const [responsibleList, setResponsibleList] = useState<Array<{id: string, keyword: string}>>([]);
  const [responsibleListIsLoading, setResponsibleListIsLoading] = useState(true);
  const [iogvList, setIogvList] = useState<Array<{id: string, keyword: string}>>([]);
  const [iogvListIsLoading, setIogvListIsLoading] = useState(true);

  const queryData = useRiskListQuery({ start: 0, limit: 1000, filter:{author:'', iogv_id:0, status:''} });
  const { data, isSuccess, isLoading, isError, error } = queryData;

  useEffect(() => {
    getAllData()
  }, [data])

  const getAllData = async () => {
    if (data?.items) {
      let tempItems = []
      for (const item of data.items) {
        const itemResponse = await ApiServices.getRiskItem({ id: item.id })
        if (itemResponse.data) {
          tempItems.push({
            ...item,
            details: itemResponse.data
          })
        }
      }
      setItems(tempItems)
      setAllDataLoaded(true)
    }
  }

  useEffect(() => {
    if (items.length > 0) {
      setResponsibleList(convertToSelectorList(items, 'responsible_id'))
      setIogvList(convertToSelectorList(items, 'iogv_id'))
      setResponsibleListIsLoading(false)
      setIogvListIsLoading(false)
    }
  }, [items])

  useEffect(() => {
    if (items) {
      let tempItems = [...items]
      if (sortSelected === 'status') {
        tempItems = [...tempItems].sort((a:any, b:any) => a.details.status < b.details.status ? 1 : -1)
      }
      if (sortSelected === 'date') {
        tempItems = [...tempItems].sort((a:any, b:any) => moment(a.details.date_start, 'YYYY-MM-DD hh:mm:ss').valueOf() < moment(b.details.date_start, 'YYYY-MM-DD hh:mm:ss').valueOf() ? 1 : -1)
      }
      if (searchString !== '') {
        tempItems = [...tempItems].filter((it: any) => it.title.toLowerCase().includes(searchString.toLowerCase()))
      }
      if (searchResponsible !== '') {
        tempItems = [...tempItems].filter((it: any) => it.details.responsible_id.title?.toLowerCase().includes(searchResponsible.toLowerCase()))
      }
      if (searchIOGV !== '') {
        tempItems = [...tempItems].filter((it: any) => it.details.iogv_id.title?.toLowerCase().includes(searchIOGV.toLowerCase()))
      }
      if (searchStatus !== '') {
        tempItems = [...tempItems].filter((it: any) => it.details.status?.toLowerCase().includes(searchStatus.toLowerCase()))
      }
      setFilteredData(tempItems)
    }
  }, [searchString, sortSelected, searchResponsible, searchIOGV, searchStatus, items])

  const searchHandle = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchString(evt.target.value)
  }

  return (
    <>
      <PageTitle>Риски</PageTitle>
      <Panel className={s.search} padding>
        <div className={s.fieldList}>
          <div className={s.fieldRow}>
            <div className={s.field}>
              <span>Ответственный</span>
              <CustomSelect
                items={responsibleList}
                isLoading={responsibleListIsLoading}
                onChange={(value) => setSearchResponsible(String(value))}
                value={searchResponsible}
                withSearch={true}
                isWide={true}
              />
            </div>
            <div className={s.field}>
              <span>ИОГВ</span>
              <CustomSelect
                items={iogvList}
                isLoading={iogvListIsLoading}
                onChange={(value) => setSearchIOGV(String(value))}
                value={searchIOGV}
                withSearch={true}
                isWide={true}
              />
            </div>
            <div className={s.field}>
              <span>Статус</span>
              <CustomSelect
                items={[
                  { id: '', keyword: 'Все' },
                  { id: 'Отработан', keyword: 'Отработан' },
                  { id: 'Выполняется', keyword: 'Выполняется' }
                ]}
                onChange={(value) => setSearchStatus(String(value))}
                value={searchStatus}
                isWide={true}
              />
            </div>
          </div>
          <div className={s.fieldRow}>
            <Field className={s.searchField} icon="search" onChange={searchHandle} value={searchString} placeholder="Введите название" />
            <div className={s.fieldShort}>
              <span>Сортировка</span>
              <CustomSelect
                items={[
                  { id: 'status', keyword: 'По статусу' },
                  { id: 'date', keyword: 'По дате начала' }
                ]}
                onChange={(value) => setSortSelected(String(value))}
                value={sortSelected}
                type="interval"
              />
            </div>
          </div>
        </div>
        <span className={s.count}>
          Рисков в работе: {filteredData.filter((it: any) => it?.details?.status === "Выполняется").length ?? "–"}
          {/*{isLoading ? <TextLoader /> : filteredData.filter((it: any) => it.details.status === "Выполняется").length ?? "–"}*/}
        </span>
      </Panel>

      <ApiInfo isLoading={isLoading || !allDataLoaded} isError={isError} error={error} />

      {isSuccess && (
        <div className={s.list}>
          {filteredData.map((item: any, index: number) => (
            <Risk key={item.id} number={index + 1} {...item} />
          ))}
        </div>
      )}
    </>
  );
}
