import { Navigation as UiNavigation } from "../../../ui/navigation";

import statistic from "./icons/statictic-min.svg";
import topic from "./icons/theme-min.svg";
import publication from "./icons/publication-min.svg";
import trending from "./icons/growingtheme-min.svg";
import newsbreak from "./icons/inforeason-min.svg";
import materials from "./icons/completematerials-min.svg";
import risks from "./icons/risks-min.svg";
import press from "./icons/press-min.svg";
import instruction from "./icons/instruction-min.svg";
import logOutIcon from "./icons/exit-min.svg";
import logo from "./icons/logo-min.svg";
import { useAppDispatch } from "../../../redux";
import { logOut } from "../../auth";

export const itemsNav = [
  {
    href: "/statistic",
    text: "Общая статистика",
    icon: statistic,
  },
  {
    href: "/topics",
    text: "Темы дня",
    icon: topic,
  },
  {
    href: "/publications",
    text: "Публикации",
    icon: publication,
  },
  {
    href: "/trending",
    text: "Быстрорастущие темы",
    icon: trending,
  },
  /*{
    href: "/summary",
    text: "Сводка тем",
    icon: materials,
    hidden: true
  },
  {
    href: "/newsbreak",
    text: "Медиапланирование",
    icon: newsbreak,
    hidden: true,
    children: [
      {
        href: "/newsbreak",
        text: "Инфоповоды",
      },
      {
        href: "/materials",
        text: "Готовые материалы",
      },
      {
        href: "/risks",
        text: "Риски",
      },
    ],
  },
  {
    href: "/press-secretary",
    text: "Работа пресс-секретарей",
    icon: press,
    hidden: true
  },*/
  {
    href: "https://tsr.glassen-it.com/instruktsiia_polzovatelia.pdf",
    text: "Инструкция",
    external: true,
    icon: instruction,
  },
];

type Props = {
  className?: string;
};

export function Navigation({ className }: Props) {
  const dispatch = useAppDispatch();
  const items = [
    ...itemsNav,
    {
      action: () => dispatch(logOut()),
      text: "Выход",
      icon: logOutIcon,
    },
  ];

  return (
    <UiNavigation
      className={className}
      title="Мониторинг"
      items={items}
    />
  );
}
